import React, {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {CalendarOutlined, UserOutlined} from '@ant-design/icons';
import {Button, Input, Tag, message, Modal} from 'antd';
import moment from 'moment';
import 'moment/locale/tr';
import './Comments.style.scss';
import PropTypes from 'prop-types';

const { TextArea } = Input;

function Comments(props) {
    const cdn = useSelector(state => state['config']['cdn']);
    const [list, handleList] = useState([]);
    const [commentAuthor, handleCommentAuthor] = useState(null);
    const [commentMail, handleCommentMail] = useState(null);
    const [commentMessage, handleCommentMessage] = useState(null);
    const [commentLoading, handleCommentLoading] = useState(false);
    const [modalAuthor, handleModalAuthor] = useState(null);
    const [modalMail, handleModalMail] = useState(null);
    const [modalMessage, handleModalMessage] = useState(null);
    const [modalLoading, handleModalLoading] = useState(false);
    const [modal, handleModal] = useState([false,0]);
    const validation = ({author, email, msg}) => {
        const emailRE = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
        let valid = true;
        if((author?.trim() === '') || (author?.trim() === undefined)){
            message.error('İsminizi boş bırakamazsınız!').then(r => r);
            valid = false;
        }
        if(!((email?.trim() === '') || (email?.trim() === undefined)) && !emailRE.test(email)){
            message.error('Geçersiz e-posta adresi!').then(r => r);
            valid = false;
        }
        if((msg?.trim() === '') || (msg?.trim() === undefined)){
            message.error('Yorumunuzu boş bırakamazsınız!').then(r => r);
            valid = false;
        }
        if(!((author?.trim() === '') || (author?.trim() === undefined)) && author.length <= 2){
            message.error('İsminiz en az 3 karakterden oluşmalıdır!').then(r => r);
            valid = false;
        }
        if(!((msg?.trim() === '') || (msg?.trim() === undefined)) && msg.length <= 2){
            message.error('Yorumunuz az 3 karakterden oluşmalıdır!').then(r => r);
            valid = false;
        }
        return valid;
    }
    const clearComment = () => {
        handleCommentMessage(null);
        handleCommentMail(null);
        handleCommentAuthor(null);
    }
    const clearModal = () => {
        handleModalMessage(null);
        handleModalMail(null);
        handleModalAuthor(null);
    }
    const sendComment = useCallback(() => {
        if(validation({
            author: commentAuthor,
            email: commentMail,
            msg: commentMessage
        })){
            handleCommentLoading(true);
            const data = {
                key: props.pageKey,
                author: commentAuthor.trim(),
                reply: 0,
                content: commentMessage.trim()
            }
            if(!((commentMail?.trim() === '') || (commentMail?.trim() === undefined))){
                data['email'] = commentMail.trim();
            }
            axios({
                url: cdn + 'comments/set',
                method: 'POST',
                data: data
            })
                .then(res => {
                    if(res.data.hasOwnProperty('success')) {
                        if(res.data.success){
                            message.success('Yorumunuz başarıyla alındı. Onaylandıktan sonra yayınlanacaktır').then(r => r);
                            clearComment();
                        }
                    }else{
                        message.error('Yorumunuz teknik bir hatadan dolayı alınamadı!').then(r => r);
                    }
                    handleCommentLoading(false);
                })
                .catch(() => {
                    message.error('Yorumunuz teknik bir hatadan dolayı alınamadı!').then(r => r);
                    handleCommentLoading(false);
                })
        }
    }, [cdn, commentAuthor, commentMail, commentMessage, props.pageKey]);
    const sendReply = useCallback((replyId) => {
        if(validation({
            author: modalAuthor,
            email: modalMail,
            msg: modalMessage
        })){
            handleModalLoading(true);
            const data = {
                key: props.pageKey,
                author: modalAuthor.trim(),
                reply: replyId,
                content: modalMessage.trim()
            }
            if(!((modalMail?.trim() === '') || (modalMail?.trim() === undefined))){
                data['email'] = modalMail.trim();
            }
            axios({
                url: cdn + 'comments/set',
                method: 'POST',
                data: data
            })
                .then(res => {
                    console.log(res);
                    if(res.data.hasOwnProperty('success')) {
                        if(res.data.success){
                            message.success('Yorumunuz başarıyla alındı. Onaylandıktan sonra yayınlanacaktır').then(r => r);
                            clearModal();
                        }
                    }else{
                        message.error('Yorumunuz teknik bir hatadan dolayı alınamadı!').then(r => r);
                    }
                    handleModalLoading(false);
                    handleModal([false, 0]);
                    clearModal();
                })
                .catch(() => {
                    message.error('Yorumunuz teknik bir hatadan dolayı alınamadı!').then(r => r);
                    handleModalLoading(false);
                    handleModal([false, 0]);
                    clearModal();
                })
        }
    }, [cdn, modalAuthor, modalMail, modalMessage, props.pageKey])
    const renderComment = (comment, index, appendClass='') => {
        return(
            <div key={comment.id}>
                <div className={'comment ' + appendClass}>
                    <div className={'head'}>
                        <Tag icon={<UserOutlined/>}>{comment.author}</Tag>
                        <Tag icon={<CalendarOutlined/>}>{moment(comment.createTime).format('D MMMM YYYY')}</Tag>
                    </div>
                    <div className={'body'} dangerouslySetInnerHTML={{ __html: comment.content }}/>
                    {appendClass !== 'reply' && <div className={'foot'}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: '1' }}/>
                            <Tag className={'reply-tag'} onClick={() => handleModal([true, comment.id])}>cevapla</Tag>
                        </div>
                    </div>}
                </div>
                {comment.hasOwnProperty('answers') && comment['answers'].map((comment, index) => renderComment(comment, index, 'reply'))}
            </div>
        );
    }
    useEffect(() => {
        axios({
            url: cdn + 'comments/getPageComments',
            method: 'POST',
            data: {
                key: props.pageKey
            }
        })
            .then(res => {
                try {
                    if(res.data.hasOwnProperty('comments')){
                        handleList(res.data.comments);
                    }
                }catch (e) {
                    handleList([]);
                }
            })
    }, [props.pageKey, cdn]);
    return (
        <div className={'comments'}>
            <div className={'to-comment'}>
                <h3 className={'title'}>Bir yorum yapın</h3>
                <Input placeholder={'İsminiz'} spellCheck={false} maxLength={30} value={commentAuthor} onChange={e => handleCommentAuthor(e.target.value)}/>
                <Input placeholder={'E-posta adresiniz (isteğe bağlı)'} spellCheck={false} value={commentMail} onChange={e => handleCommentMail(e.target.value)}/>
                <small>Size geri dönüş yapabilmemiz için e-posta adresinizi yazabilirsiniz. Kimseyle paylaşılmayacaktır.</small>
                <TextArea placeholder={'Yorumunuz'} spellCheck={false} rows={3} value={commentMessage} onChange={e => handleCommentMessage(e.target.value)}/>
                <Button type={'primary'} onClick={sendComment} loading={commentLoading}>Gönder</Button>
            </div>
            <div className={'list'}>
                <h3 className={'title'}>Yorumlar</h3>
                {list.map(renderComment)}
                {list.length === 0 && <div style={{ padding: '.5rem .5rem .5rem 0' }}>Henüz bu sayfaya hiç yorum yapılmadı.</div>}
            </div>
            <Modal
                title={'Yoruma cevap ver'}
                visible={modal[0]}
                onCancel={() => {
                    handleModal([false, 0]);
                    clearModal();
                }}
                footer={[
                    <Button type={'primary'} onClick={() => sendReply(modal[1])} loading={modalLoading}>Gönder</Button>
                ]}
            >
                <div className={'to-comment'}>
                    <Input placeholder={'İsminiz'} spellCheck={false} maxLength={30} value={modalAuthor} onChange={e => handleModalAuthor(e.target.value)}/>
                    <Input placeholder={'E-posta adresiniz (isteğe bağlı)'} spellCheck={false} value={modalMail} onChange={e => handleModalMail(e.target.value)}/>
                    <small>Size geri dönüş yapabilmemiz için e-posta adresinizi yazabilirsiniz. Kimseyle paylaşılmayacaktır.</small>
                    <TextArea placeholder={'Yorumunuz'} spellCheck={false} rows={5} value={modalMessage} onChange={e => handleModalMessage(e.target.value)}/>
                </div>
            </Modal>
        </div>
    );
}

Comments.propTypes = {
    pageKey: PropTypes.string.isRequired
};

export default Comments;
