import React from 'react';
import './ContentCorner.style.scss';
import PropTypes from 'prop-types';

function ContentCorner(props) {
    const id = 'content-file-' + Math.floor(Math.random() * 100000);
    return (
        <div className={'content-file ' + props.className} id={id}>
            <style dangerouslySetInnerHTML={{ __html: '#'+id+' .content .file-item{ border-left: 2px solid ' + props.color + ' }' }}/>
            <style dangerouslySetInnerHTML={{ __html: '#'+id+' .content .file-item:after{ border: 4px solid '+ props.backgroundColor +'; background-color: ' + props.color + ' }' }}/>
            <div className={'title'} style={{ color: props.color }}>{props.title}</div>
            <div className={'content'}>
                {props.content.map((item, index) => {
                    return(
                        <a
                            key={index}
                            style={{ color: props.color }}
                            className={'file-item'}
                            href={item.url}
                            target={'_blank'}
                            rel={'noreferrer'}
                        >
                            {item.text}
                        </a>
                    );
                })}
            </div>
        </div>
    );
}

ContentCorner.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    content: PropTypes.array.isRequired
};

ContentCorner.defaultProps = {
    className: '',
    color: '#000000',
    backgroundColor: '#ffffff',
};

export default ContentCorner;
