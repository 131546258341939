import React, {useCallback, useEffect, useState} from 'react';
import './Cards.style.scss';
import { Tag } from 'antd';
import moment from 'moment';
import 'moment/locale/tr';
import PropTypes from 'prop-types';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';

function Cards(props) {
    const cardsId = 'cards-' + Math.floor(Math.random() * 10000);
    const cardsInsideId = 'cards-inside-' + Math.floor(Math.random() * 10000);
    const [focus, handleFocus] = useState(false);
    const [holdingPosition, handleHoldingPosition] = useState(0);
    const [dragDistance, handleDragDistance] = useState(0);
    const [width, handleWidth] = useState(0);
    const [direction, handleDirection] = useState('right');
    const [page, handlePage] = useState(0);
    const [maxMarginLeft, handleMaxMarginLeft] = useState(0);
    const [marginLeft, handleMarginLeft] = useState(0);
    const dragBreak = useCallback((clientX, leave=true) => {
        if(leave){
            handleFocus(false);
            handleHoldingPosition(0);
            handleDragDistance(0);
            const newMarginLeft = marginLeft + (clientX - holdingPosition);
            if(newMarginLeft >= 0){
                handleMarginLeft(0);
            }
            else if(newMarginLeft <= maxMarginLeft){
                handleMarginLeft(maxMarginLeft);
            }
            else{
                const num = Math.abs(Math.floor(newMarginLeft / width));
                direction === 'right' ? handlePage(num) : handlePage(num-1);
                const reConstructedMarginLeft = - ((direction === 'right' ? num : (num - 1)) * width);
                if(reConstructedMarginLeft <= maxMarginLeft)
                    handleMarginLeft(maxMarginLeft);
                else
                    handleMarginLeft(reConstructedMarginLeft);
            }
        }
    }, [holdingPosition, marginLeft, maxMarginLeft, direction, width]);
    const moveEvent = useCallback(clientX => {
        if(focus){
            const newDragDistance = (clientX - holdingPosition);
            Math.sign(newDragDistance) === -1 ? handleDirection('right') : handleDirection('left');
            const newMarginLeft = marginLeft + newDragDistance;
            if(newMarginLeft >= 0)
                handleDragDistance(0);
            else if(newMarginLeft <= maxMarginLeft)
                handleDragDistance(maxMarginLeft - marginLeft);
            else
                handleDragDistance(newDragDistance);
        }
    }, [holdingPosition, marginLeft, maxMarginLeft, focus]);
    useEffect(() => {
        const cardsWidth = document.getElementById(cardsId).offsetWidth;
        const cardsInsideWidth = document.getElementById(cardsInsideId).scrollWidth;
        const width = document.querySelector('#' + cardsInsideId + ' .card').offsetWidth + 19.2;
        handleMaxMarginLeft(cardsWidth - cardsInsideWidth);
        handleWidth(width);
    },[cardsId, cardsInsideId]);
    return (
        <div className={'cards'} id={cardsId}>
            <div
                className={'arrow left'}
                style={{ display: page === 0 ? 'none' : 'flex' }}
                onClick={() => {
                     handlePage(page - 1)
                     handleMarginLeft( - (page - 1) * width );
                }}
            >
                <LeftOutlined/>
            </div>
            <div
                className={'arrow right'}
                style={{ display: maxMarginLeft === marginLeft ? 'none' : 'flex' }}
                onClick={() => {
                    handlePage(page + 1)
                    const newMarginLeft = - (page + 1) * width;
                    if(newMarginLeft <= maxMarginLeft){
                        handleMarginLeft( maxMarginLeft);
                    }else{
                        handleMarginLeft( - (page + 1) * width );
                    }
                }}
            >
                <RightOutlined/>
            </div>
            <div
                className={'cards-inside'}
                id={cardsInsideId}
                style={{
                    transition: focus ? 'margin 0ms' : 'margin 300ms',
                    marginLeft: (marginLeft + dragDistance) + 'px'
                }}
                onMouseDown={e => {
                    handleFocus(true);
                    handleHoldingPosition(e.clientX);
                }}
                onMouseUp={e => dragBreak(e.clientX)}
                onMouseLeave={e => dragBreak(e.clientX, focus)}
                onMouseMove={e => moveEvent(e.clientX)}
                onTouchStart={e => {
                    handleFocus(true);
                    handleHoldingPosition(e.touches[0].clientX);
                }}
                onTouchEnd={e => dragBreak(e.changedTouches[0].clientX)}
                onTouchMove={e => moveEvent(e.touches[0].clientX)}
            >
                {props.data.map((card, index) => {
                    return( <div className={'card'} key={index}>
                        <div className={'image'} style={{ backgroundImage: 'url(' + card.image + ')'}}/>
                        <div className={'line'} style={{
                            background: 'linear-gradient(45deg, rgba('+card.color1+',1) 0%, rgba('+card.color2+',1) 100%)'
                        }}/>
                        <div className={'content'}>
                            <div className={'title'}>{card.title}</div>
                            <div className={'description'} dangerouslySetInnerHTML={{ __html: card.content?.replace('\n','<br>') }}/>
                            <div className={'event'}>
                                <div className={'time'}>{moment(card.date).format('D MMMM YYYY HH:mm')}</div>
                                <div style={{ flex: '1' }}/>
                                {(typeof card.URL === 'string' && card.URL?.trim() !== '') &&
                                <a href={card.URL}><Tag className={'read-more'}>devamını oku</Tag></a>}
                            </div>
                        </div>
                    </div>);
                })}
            </div>
        </div>
    );
}

Cards.propTypes = {
    data: PropTypes.array.isRequired
};

export default Cards;
