import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Tag} from 'antd';
import axios from 'axios';
import {CalendarOutlined, CommentOutlined, EditOutlined} from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/tr';
import PropTypes from 'prop-types';

function PageInfoBox(props) {
    const cdn = useSelector((state) => state['config']['cdn']);
    const [author, handleAuthor] = useState('yazar');
    const [authorURL, handleAuthorURL] = useState(null);
    useEffect(() => {
        axios({
            url: cdn + 'components/get',
            method: 'POST',
            data: {
                title: props.person
            }
        })
            .then(res => {
                try{
                    handleAuthor(res.data.component.component.name);
                    handleAuthorURL(res.data.component.component.url)
                }catch (e) {
                    handleAuthor('yazar bulunamadı');
                }
            })
    }, [props.person, cdn]);
    return (
        <div className={'page-info-box'} style={{ margin: '.5rem' , display: 'flex' }}>
            <div className={'piece'}>
                <a href={authorURL} target={'_blank'} rel={'noreferrer'}><Tag icon={<EditOutlined/>}>{author}</Tag></a>
            </div>
            {props.showCommentCount &&
                <div className={'piece'}>
                    <Tag icon={<CommentOutlined/>}>{props.commentCount}</Tag>
                </div>
            }
            {props.showDate &&
            <div className={'piece'}>
                <Tag icon={<CalendarOutlined/>}>{moment(props.date).format('D MMMM YYYY')}</Tag>
            </div>
            }
        </div>
    );
}


PageInfoBox.propTypes = {
    person: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    commentCount: PropTypes.number.isRequired,
    showDate: PropTypes.bool.isRequired,
    showCommentCount: PropTypes.bool.isRequired
};

export default PageInfoBox;
