import React, {useCallback, useEffect, useState} from 'react';
import {Button, Input, message} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {stopLoading} from '../../../features/config/config';
import axios from 'axios';

function Admin() {
    const dispatch = useDispatch();
    const cdn = useSelector((state) => state['config']['cdn']);
    const admin = useSelector((state) => state['config']['admin']);
    const [username, handleUsername] = useState(null);
    const [password, handlePassword] = useState(null);
    const [loading, handleLoading] = useState(false);
    const validate = useCallback(({username, password}) => {
        if(username === null || username === undefined || username?.trim() === '') {
            message.error('Kullanıcı adınızı boş bırakamazsınız!').then(r => r);
            return false;
        }
        else if(password === null || password === undefined || password?.trim() === ''){
            message.error('Şifrenizi boş bırakamazsınız!').then(r => r);
            return false;
        }else{
            handleLoading(true);
            return true;
        }
    }, []);
    const login = useCallback(() => {
        if(validate({username, password})){
            axios({
                url: cdn + 'config/login',
                method: 'POST',
                data: { username, password }
            })
                .then(res => {
                    handleLoading(false);
                    if(res.data.success){
                        message.success('Giriş başarılı yönlendiriliyorsunuz')
                            .then(() => {
                                window.location.href = admin + 'login?token=' + res.data.token
                            });
                    }else{
                        message.error('Eşleşmeyen kullanıcı adı ve/ya şifre!').then(r => r);
                    }
                })
                .catch(() => {
                    handleLoading(false);
                    message.error('Eşleşmeyen kullanıcı adı ve/ya şifre!').then(r => r);
                });
        }
    }, [username, password, validate, cdn, admin]);
    useEffect(() => {
        dispatch(stopLoading());
    }, [dispatch]);
    return (
        <div className={'admin-page'} style={{
            width: '300px',
            margin: '0 auto',
            minHeight: 'calc(80vh - 0.8rem)',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center'
        }}>
            <div style={{
                fontWeight: '700',
                fontFamily: '\'Ubuntu\', sans-serif',
                fontSize: '2.5rem',
                lineHeight: '1rem',
                marginBottom: '2rem'
            }}>
                ADMİN PANELİ
            </div>
            <Input
                type={'text'}
                style={{ marginBottom: '1rem' }}
                spellCheck={false}
                placeholder={'Kullanıcı adınız'}
                value={username}
                onChange={e => handleUsername(e.target.value)}
            />
            <Input
                type={'password'}
                style={{ marginBottom: '1rem' }}
                placeholder={'Şifreniz'}
                value={password}
                onChange={e => handlePassword(e.target.value)}
                onPressEnter={e => {
                    document.getElementById('login').click()
                    e.target.blur();
                }}
            />
            <Button
                id={'login'}
                onClick={login}
                loading={loading}
            >
                Giriş yap
            </Button>
        </div>
    );
}

Admin.defaultProps = {};

export default Admin;
