import React, {useEffect} from 'react';
import {Image} from 'antd';
import PropTypes from 'prop-types';
import './Gallery.style.scss';

function Gallery(props) {
    useEffect(() => {
        const previewTexts = Array.from(document.querySelectorAll('.ant-image-mask-info'));
        for (const previewText of previewTexts) {
            try {
                previewText.childNodes[1].remove();
            }catch (e) {
                return e;
            }
        }
    }, [])
    return (
        <div className={'gallery'} style={{ margin: '.5rem' }}>
            <Image.PreviewGroup>
                {props.data.map((img, index) => {
                    return(
                        <Image key={index} className={'gallery-image'} src={img.url} />
                    );
                })}
            </Image.PreviewGroup>
        </div>
    );
}

Gallery.defaultProps = {
    data: PropTypes.array.isRequired
};

export default Gallery;
