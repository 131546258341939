import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import './Image.style.scss';
import axios from 'axios';

function Image(props) {
    const cdn = useSelector((state) => state['config']['cdn']);
    const imageId = 'image-' + Math.floor(Math.random() * 10000);
    const [longImage, handleLongImage] = useState(false);
    const [caption, handleCaption] = useState(props.caption);
    useEffect(() => {
        setTimeout(() => {
            try{
                const img = document.getElementById(imageId);
                if(img.clientHeight > img.clientWidth){
                    handleLongImage(true);
                }
            }catch (e) {
                handleLongImage(false);
            }
        },500);
        axios({
            url: cdn + 'pages/convertURL',
            method: 'POST',
            data: {
                text: props.caption
            }
        })
            .then(res => {
                if(res.data.hasOwnProperty('text')){
                    handleCaption(res.data.text);
                }
            })
    }, [imageId, props.caption, cdn]);
    return (
        props.span ? (
            <span style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <span className={'image-component'} style={ { display: 'block' }}>
                    <span className={'image-inside'} id={imageId} style={{ height: longImage && '20rem'}}>
                        <img src={props.url} alt={props.caption} style={{
                            width: longImage && 'auto',
                            height: longImage && '100%'
                        }}/>
                    </span>
                </span>
            </span>) : (<div className={'image-component'}>
            <div className={'image-inside'} id={imageId} style={{ height: longImage && '20rem'}}>
                <img src={props.url} alt={props.caption} style={{
                    width: longImage && 'auto',
                    height: longImage && '100%'
                }}/>
            </div>
            {(typeof caption === 'string' && caption?.trim() !== '') &&
            <div className={'caption'} dangerouslySetInnerHTML={{ __html: caption }}/>}
        </div>)
    );
}

Image.propTypes = {
    url: PropTypes.string.isRequired,
    span: PropTypes.bool,
    caption: PropTypes.string
}

export default Image;
