import React from 'react';
import {Button} from 'antd';

function NotFound(props) {
    return (
        <div className={'not-found'} style={{
            textAlign: 'center'
        }}>
            <div style={{
                marginTop: '1rem',
                fontWeight: '700',
                fontSize: '8rem'
            }}>404</div>
            <div>
                Aradığınız sayfayı bulamadık<br/>
                belki kaldırılmış belki de hiç var olmamıştır.
            </div>
            <div style={{
                marginTop: '1.2rem'
            }}>
                <a href={'/'} style={{margin: '.5rem'}}>
                    <Button>Ana sayfa</Button>
                </a>
                <a href={'/iletisim'} style={{margin: '.5rem'}}>
                    <Button>İletişim</Button>
                </a>
            </div>
        </div>
    );
}

export default NotFound;
