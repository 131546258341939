import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

function EmbedYoutube(props) {
    const iframeId = 'iframe-' + Math.floor(Math.random()*10000);
    const [height, handleHeight] = useState(400);
    useEffect(() => {
        const width = document.getElementById(iframeId).offsetWidth;
        if((350 < width) && (width <= 480))
            handleHeight(250);
        else if(width <= 350)
            handleHeight(200);
    }, [iframeId]);
    const embedLinkGenerator = (url) => {
        try{
            const yt = new URLSearchParams(new URL(url).search);
            return 'https://www.youtube.com/embed/' + yt.get('v');
        }catch (e) {
            return 'https://www.youtube.com/embed/';
        }
    }
    return (
        <div className={'embed-youtube'} style={{
            margin: '.5rem',
            width: 'calc(min(720px,100%) - 1rem)',
            height: height + 'px'
        }}>
            <iframe
                id={iframeId}
                title={iframeId}
                style={{
                    width: 'min(720px,100%)',
                    height: height + 'px'
                }}
                src={embedLinkGenerator(props.url)}
                frameBorder={0}
                allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'}
                allowFullScreen
            />
        </div>
    );
}

EmbedYoutube.propTypes = {
    url: PropTypes.string.isRequired
}

export default EmbedYoutube;
