import React, {useEffect, useState} from 'react';
import {Button, Input, message} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import './Contact.scss';
import BreadCrumb from '../../common-use/BreadCrumb/BreadCrumb';
import TitleComponent from '../../common-use/TitleComponent/TitleComponent';
import axios from 'axios';
import {stopLoading} from '../../../features/config/config';

const {TextArea } = Input;

function Contact() {
    const dispatch = useDispatch();
    const cdn = useSelector((state) => state['config']['cdn']);
    const database = useSelector((state) => state['config']['database']);
    const [name,handleName] = useState(null);
    const [email,handleEmail] = useState(null);
    const [subject,handleSubject] = useState(null);
    const [content,handleContent] = useState(null);
    const [loading,handleLoading] = useState(false);
    const [contact,handleContact] = useState({
        address: null,
        email: null,
        phoneNumber: null,
    });
    const validation = ({name, email, subject, content}) => {
        const emailRE = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i
        let valid = true;
        if((name?.trim() === '') || (name?.trim() === undefined)){
            message.error('Ad soyadınızı boş bırakamazsınız!').then(r => r);
            valid = false;
        }
        if((email?.trim() === '') || (email?.trim() === undefined)){
            message.error('E-posta adresinizi boş bırakamazsınız!').then(r => r);
            valid = false;
        }
        if(!((email?.trim() === '') || (email?.trim() === undefined)) && !emailRE.test(email)){
            message.error('Geçersiz e-posta adresi!').then(r => r);
            valid = false;
        }
        if((subject?.trim() === '') || (subject?.trim() === undefined)){
            message.error('Konuyu boş bırakamazsınız!').then(r => r);
            valid = false;
        }
        if(!((name?.trim() === '') || (name?.trim() === undefined)) && name.length <= 2){
            message.error('Ad soyadınız en az 3 karakterden oluşmalıdır!').then(r => r);
            valid = false;
        }
        if((content?.trim() === '') || (content?.trim() === undefined)){
            message.error('Mesajınızı boş bırakamazsınız!').then(r => r);
            valid = false;
        }
        if(!((content?.trim() === '') || (content?.trim() === undefined)) && content.length <= 20){
            message.error('Mesajınız az 20 karakterden oluşmalıdır!').then(r => r);
            valid = false;
        }
        return valid;
    }
    const clear = () => {
        handleName(null);
        handleEmail(null);
        handleSubject(null);
        handleContent(null);
    }
    const send = () => {
        if(validation({name, email, subject, content})){
            handleLoading(true);
            axios({
                url: cdn + 'contact/sendMail',
                method: 'POST',
                data: { name, email, subject, content }
            })
                .then(res => {
                    if(res.data.hasOwnProperty('success')){
                        if(res.data.success){
                            message.success('Mesajınız başarılı bir şekilde alındı.').then(r => r);
                        }else{
                            message.error('Mesajınız teknik bir sorundan dolayı alınamadı!').then(r => r);
                        }
                    }else{
                        message.error('Mesajınız teknik bir sorundan dolayı alınamadı!').then(r => r);
                    }
                    handleLoading(false);
                    clear();
                })
                .catch(() => {
                    message.error('Mesajınız teknik bir sorundan dolayı alınamadı!').then(r => r);
                    handleLoading(false);
                })
        }
    }
    useEffect(() => {
        dispatch(stopLoading());
        if(database.hasOwnProperty('contact')){
            console.log(database.contact);
            handleContact(database.contact);
        }
    }, [dispatch, database])
    return (
        <div className={'contact'}>
            <TitleComponent value={'İletişim'} color={'0,0,0'} level={'1'}/>
            <BreadCrumb data={[
                { title: 'Ana sayfa', URL: 'https://ankaratibbiyeliler.org.tr' },
                { title: 'İletişim', URL: null}
            ]}/>
            <div className={'contact-boxes'}>
                <div className={'box address'}>
                    <div className={'icon'}>
                        <i className={'fas fa-map-marker-alt'}/>
                    </div>
                    <div className={'text'}>
                        <a href={'https://goo.gl/maps/RSg2TRytDZkyeGhx7'} rel={'noreferrer'} target={'_blank'}>
                            {contact.address}
                        </a>
                    </div>
                </div>
                <div className={'box email'}>
                    <div className={'icon'}>
                        <i className={'fas fa-envelope'}/>
                    </div>
                    <div className={'text'}>
                        <a href={'mailto:' + contact.email} target={'_blank'} rel={'noreferrer'}>
                            {contact.email}
                        </a>
                    </div>
                </div>
                <div className={'box phone'}>
                    <div className={'icon'}>
                        <i className={'fas fa-phone-alt'}/>
                    </div>
                    <div className={'text'}>
                        <a href={'tel:' + contact.phoneNumber} target={'_blank'} rel={'noreferrer'}>
                            {contact.phoneNumber}
                        </a>
                    </div>
                </div>
            </div>
            <div className={'form'}>
                <div className={'label'}>
                    <div className={'text'}>Adınız soyadınız</div>
                    <Input
                        spellCheck={false}
                        placeholder={'Size nasıl hitap edelim?'}
                        value={name}
                        onChange={e => handleName(e.target.value)}
                    />
                </div>
                <div className={'label'}>
                    <div className={'text'}>E-posta adresiniz</div>
                    <Input
                        spellCheck={false}
                        placeholder={'Size geri dönüş yapabilmemiz için gerekli'}
                        value={email}
                        onChange={e => handleEmail(e.target.value)}
                    />
                </div>
                <div className={'label'}>
                    <div className={'text'}>Konu</div>
                    <Input
                        spellCheck={false}
                        placeholder={'Hangi konuda bizimle iletişim kurmak istiyorsunuz?'}
                        value={subject}
                        onChange={e => handleSubject(e.target.value)}
                    />
                </div>
                <div className={'label'}>
                    <div className={'text'}>Mesajınız</div>
                    <TextArea
                        rows={8}
                        spellCheck={false}
                        placeholder={'Bizimle iletişime geçme sebebinizi ayrıntılı olarak yazarsanız sizlere daha iyi yardımcı olabiliriz'}
                        value={content}
                        onChange={e => handleContent(e.target.value)}
                    />
                </div>
                <div className={'label'}>
                    <Button type={'primary'} onClick={send} loading={loading}>Gönder</Button>
                </div>
            </div>
            <iframe
                src={'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3059.31438098576!2d32.863485!3d39.934357!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x935e17a81e23703d!2sAnkara%20T%C4%B1bbiyeliler!5e0!3m2!1str!2str!4v1629648299797!5m2!1str!2str'}
                title={'Ankara Tıbbiyeliler'}
                width={'100%'}
                height={'450'}
                allowFullScreen={''}
                loading={'lazy'}
                style={{ border: '0' }}
            />
        </div>
    );
}

Contact.defaultProps = {};

export default Contact;
