import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import NotFound from '../NotFound/NotFound';
import createPage from '../../createPage';
import {stopLoading} from '../../../features/config/config';

function NormalPage() {
    const dispatch = useDispatch();
    const cdn = useSelector((state) => state['config']['cdn']);
    const [notFound, handleNotFound] = useState(false);
    const [pageData, handlePageData] = useState(null);
    useEffect(() => {
        let path;
        if(window.location.pathname.split('/').length === 4){
            path = window.location.pathname.substr(0, (window.location.pathname.length-1));
        }else{
            path = window.location.pathname;
        }
        axios({
            url: cdn + 'pages/getClient',
            method: 'POST',
            data: { path }
        })
            .then(res => {
                if(res.data.hasOwnProperty('success')) {
                    if(res.data.success) {
                        handlePageData(res.data.page);
                        dispatch(stopLoading());
                    }else {
                        handleNotFound(true);
                        dispatch(stopLoading());
                    }
                }else{
                    handleNotFound(true);
                    dispatch(stopLoading());
                }
            })
            .catch(() => {
                handleNotFound(true);
                dispatch(stopLoading());
            })
    }, [cdn, dispatch]);
    return (notFound ? <NotFound/> :
        <div className={'normal-page'}>
            {pageData !== null && createPage(pageData)}
        </div>
    );
}

export default NormalPage;
