import React from 'react';
import {Alert} from 'antd';
import PropTypes from 'prop-types';

function AlertBox(props) {
    return (
        <div className={'alert-box'} style={{ margin: '.5rem' }}>
            {(typeof props.title === 'string' && props.title?.trim() !== '') ?
                <Alert message={props.title} description={props.content} showIcon={props.icon} type={props.type}/> :
                <Alert message={props.content} showIcon={props.icon} type={props.type}/>}
        </div>
    );
}

AlertBox.propTypes = {
    type: PropTypes.string.isRequired,
    title: PropTypes.string,
    content: PropTypes.string.isRequired,
    icon: PropTypes.bool.isRequired
};

export default AlertBox;
