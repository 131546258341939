import React, {useEffect, useState} from 'react';
import './TextComponent.style.scss';
import PropTypes from 'prop-types';
import HtmlToReact, {Parser} from 'html-to-react';
import Image from '../Image/Image';

function TextComponent(props) {
    const [elements, handleElements] = useState([]);
    useEffect(() => {
        const htmlToReactParser = new Parser();
        const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
        const isValidNode = () => true;
        const processingInstructions = [
            {
                shouldProcessNode: function (node) {
                    return (node.name && node.name === 'figure') || (node.name === 'img' && node.parent.type === 'tag');
                },
                processNode: function (node, children, index) {
                    if(node.type === 'tag'){
                        const src = node['attribs'].src;
                        return <Image url={src} key={Math.random()} span={true}/>;
                    }else{
                        const src = children[0].props.src;
                        const caption = children.length === 2 && children[1].props.children;
                        return <div key={Math.random()} style={{
                            width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'
                        }}><Image url={src} caption={caption}/></div>;
                    }
                }
            },
            {
                shouldProcessNode: function (node) {
                    return true;
                },
                processNode: processNodeDefinitions.processDefaultNode,
            }
        ];
        handleElements(htmlToReactParser.parseWithInstructions(props.data, isValidNode, processingInstructions));
    }, [props.data])
    return (
        <div className={'text-component'} style={{ margin: '.5rem'}}>
            {elements.length === 0 ?
                <div className={'ck-content'} dangerouslySetInnerHTML={{ __html: props.data }}/> :
                <div className={'ck-content'}>{elements}</div>}
        </div>
    );
}

TextComponent.propTypes = {
    data: PropTypes.string.isRequired
}

TextComponent.defaultProps = {
    data: '<p>İçerik yok</p>'
};

export default TextComponent;
