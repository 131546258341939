import React from 'react';
import moment from 'moment';
import 'moment/locale/tr';
import './Calendar.style.scss'
import PropTypes from 'prop-types';


function Calendar(props) {
    return (
        <div className={'calendar'}>
            {props.data.map((item, index) => {
                /* garanti - alttaki 2 satır */
                item.title = item.title === null ? '' : item.title;
                item.description = item.description === null ? '' : item.description;
                const date = moment(item.date).format('D MMMM YYYY').split(' ');
                return (
                    <div className={'calendar-item'} key={index}>
                        <div className={'date-box'}>
                            <div className={'day'}>{date[0]}</div>
                            <div className={'month'}>{date[1]}</div>
                            <div className={'year'}>{date[2]}</div>
                        </div>
                        {(typeof item.URL === 'string' && item.URL?.trim() !== '') ?
                            <a className={'text-box'} href={item.URL} target={'_blank'} rel={'noreferrer'}>
                                <div
                                    className={'box-title'}
                                    dangerouslySetInnerHTML={{__html: item.title.replace('\n','<br>')}}
                                />
                                <div
                                    className={'box-description'}
                                    dangerouslySetInnerHTML={{__html: item.description.replace('\n','<br>')}}
                                />
                            </a> :
                            <div className={'text-box'}>
                                <div
                                    className={'box-title'}
                                    dangerouslySetInnerHTML={{__html: item.title.replace('\n','<br>')}}
                                />
                                <div
                                    className={'box-description'}
                                    dangerouslySetInnerHTML={{__html: item.description.replace('\n','<br>')}}
                                />
                            </div>
                        }
                    </div>
                );
            })}
        </div>
    );
}

Calendar.propTypes = {
    data: PropTypes.array.isRequired
};

export default Calendar;
