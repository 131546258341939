import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import './Header.style.scss';
import LOGO from '../../../assets/images/logo.png';
import SocialMediaBar from '../SocialMediaBar/SocialMediaBar';
import NavMenu from '../NavMenu/NavMenu';

const { Search } = Input;

function Header(props) {
    const [search, handleSearch] = useState(null);
    const [drawer, handleDrawer] = useState(false);
    const drawerCheck = (e) => {
        const flexWidth = document.getElementById('menu-drawer').offsetWidth;
        if(flexWidth <= 20){
            handleDrawer(true);
        }
    }
    useEffect(() => {
        if(search !== null && search?.trim() !== ''){
            window.location.href = window.location.protocol + '//' + window.location.host + '/ara?q=' + search;
        }
    }, [search]);
    useEffect(() => drawerCheck(), [props.config.menu])
    useEffect(() => {
        window.addEventListener('resize', drawerCheck);
    }, [])
    const socailMediaBarLinks = () => {
        const links = [];
        const logos = Object.keys(props.config.socialMedia);
        for (const link of logos) {
            if(props.config.socialMedia[link] !== null){
                links.push({ url: props.config.socialMedia[link], logo: link});
            }
        }
        return links;
    }
    return (
        <div className={'page-header'}>
            <div id={'page-header-label-1'} className={'label-1 inner-space'}>
                <SocialMediaBar links={socailMediaBarLinks()}/>
                <a className={'mail'} target={'_blank'} href={'mailto:' + props.config.contact.email} rel={'noreferrer'}>
                    <i className={'far fa-paper-plane'}/>
                    <div className={'text'}>
                        {props.config.contact.email}
                    </div>
                </a>
                <div style={{ flex: '1' }}/>
                <Search style={{ width: '10rem' }} placeholder={'Sitede ara...'} spellCheck={false} onSearch={handleSearch}/>
            </div>
            <div id={'page-header-label-2'} className={'label-2 inner-space'}>
                <a href={'https://ankaratibbiyeliler.org.tr'}>
                    <img className={'logo'} src={LOGO} alt={'Ankara Tıbbiyeliler'}/>
                </a>
                <div id={'menu-drawer'} style={{ flex: '1' }}/>
                <NavMenu links={props.config.menu} drawer={drawer}/>
            </div>
            <div id={'page-header-label-3'} className={'label-3'}/>
        </div>
    );
}

Header.defaultProps = {
    config: {
        menu: [],
        socialMedia: {
            twitter: null,
            instagram: null,
            youtube: null,
            telegram: null
        },
        contact: {
            email: null,
            phoneNumber: null,
            address: null,
            about: null
        }
    }
};

export default Header;
