import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './CoverImage.style.scss';
import axios from 'axios';
import {useSelector} from 'react-redux';

function CoverImage(props) {
    const cdn = useSelector((state) => state['config']['cdn']);
    const [caption, handleCaption] = useState(null);
    useEffect(() => {
        axios({
            url: cdn + 'pages/convertURL',
            method: 'POST',
            data: {
                text: props.caption
            }
        })
            .then(res => {
                if(res.data.hasOwnProperty('text')){
                    handleCaption(res.data.text);
                }
            })
    })
    return (
        <div className={'cover-image'}>
            <div className={'cover-image-inside'}>
                <div className={'background'} style={{ backgroundImage: 'url(' + props.url + ')' }}/>
                <img src={props.url} alt={'Ankara Tıbbiyeliler'}/>
            </div>
            {(typeof caption === 'string' && caption?.trim() !== '') &&
            <div className={'caption'} dangerouslySetInnerHTML={{ __html: caption }}/>}
        </div>

    );
}

CoverImage.propTypes = {
    url: PropTypes.string.isRequired,
    caption: PropTypes.string
};

CoverImage.defaultProps = {
    url: null,
    caption: null
};

export default CoverImage;
