import React from 'react';
import PropTypes from 'prop-types';
import './TitleComponent.style.scss';

function TitleComponent(props) {
    let element;
    switch (props.level) {
        case '1':
            element = <h1 style={{ color: 'rgb(' + props.color + ')', margin: '.5rem'}}>{props.value}</h1>;
            break;
        case '2':
            element = <h2 style={{ color: 'rgb(' + props.color + ')', margin: '.5rem'}}>{props.value}</h2>;
            break;
        case '3':
            element = <h3 style={{ color: 'rgb(' + props.color + ')', margin: '.5rem'}}>{props.value}</h3>;
            break;
        case '4':
            element = <h4 style={{ color: 'rgb(' + props.color + ')', margin: '.5rem'}}>{props.value}</h4>;
            break;
        case '5':
            element = <h5 style={{ color: 'rgb(' + props.color + ')', margin: '.5rem'}}>{props.value}</h5>;
            break;
        default:
            break;
    }
    return (
        <div className={'title'}>
            {element}
        </div>
    );
}

TitleComponent.propTypes = {
    value: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired
}

export default TitleComponent;
