import { createSlice } from '@reduxjs/toolkit'

export const configSlice = createSlice({
    name: 'config',
    initialState: {
        cdn: 'https://cdn.ankaratibbiyeliler.org.tr/',
        main: 'https://ankaratibbiyeliler.org.tr/',
        admin: 'https://admin.ankaratibbiyeliler.org.tr/',
        loading: true,
        database: {}
    },
    reducers: {
        setConfig: (state, action) => {
            state.database = action.payload;
        },
        stopLoading: (state) => {
            state.loading = false;
        },
    },
})

export const { setConfig, stopLoading } = configSlice.actions

export default configSlice.reducer