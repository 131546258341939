import React from 'react';
import PropTypes from 'prop-types';
import {Carousel} from 'antd';
import './Slider.style.scss';

const positions = {
    'bottomCenter': { justifyContent: 'center', alignItems: 'flex-end' },
    'topCenter': { justifyContent: 'center', alignItems: 'flex-start'},
    'bottomRight': { justifyContent: 'flex-end', alignItems: 'flex-end' },
    'topRight': { justifyContent: 'flex-end', alignItems: 'flex-start'},
    'bottomLeft': { justifyContent: 'flex-start', alignItems: 'flex-end' },
    'topLeft': { justifyContent: 'flex-start', alignItems: 'flex-start'},
}

function Slider(props) {
    return (
        <div className={'slider'}>
            <Carousel autoplay={props.auto}>
                {props.data.map((slide, index) => {
                    return(
                        <div
                            key={index}
                        >
                            <a
                                href={slide.URL}
                                className={'slide'}
                                style={{
                                    backgroundImage: 'url(' + slide.image + ')',
                                    ...positions[slide.position],
                                }}
                            >
                                {(typeof slide.title === 'string' && slide.title?.trim() !== '') && <div
                                    className={'title'}
                                    style={{
                                        color: 'rgb(' + slide.fontColor + ')',
                                        backgroundColor: 'rgb(' + slide.backgroundColor + ')'
                                    }}
                                    dangerouslySetInnerHTML={{__html: slide.title.replace('\n','<br>')}}
                                />}
                            </a>
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
}

Slider.propTypes = {
    auto: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired
};

export default Slider;
