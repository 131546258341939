import React, {useEffect, useState} from 'react';
import './NavMenu.style.scss';
import {Dropdown, Menu} from 'antd';
import {Burger} from '@mantine/core';

const menuCreator = (cascader) => {
    return(
        <Menu>
            {cascader.map(item => {
                return(
                    <Menu.Item key={item.key}>
                        <a href={item.url}>{item.title}</a>
                    </Menu.Item>
                );
            })}
        </Menu>
    );
}

function NavMenu(props) {
    const [drawer, handleDrawer] = useState(false);
    useEffect(() => {
        const drawerElement = document.getElementById('drawer');
        if (drawerElement !== null) {
            const header = document.getElementById('page-header-label-2');
            const drawerTop = header.offsetTop + header.offsetHeight;
            drawerElement.style.height = (document.body.offsetHeight - drawerTop).toString() + 'px';
            if (drawer) {
                document.body.style.overflow = 'hidden';
                drawerElement.style.top = document.body.offsetHeight.toString() + 'px';
                drawerElement.style.display = 'flex';
                setTimeout(() => {
                    drawerElement.style.top = drawerTop.toString() + 'px';
                }, 100);
            } else {
                document.body.style.overflow = 'auto';
                drawerElement.style.top = document.body.offsetHeight.toString() + 'px';
                setTimeout(() => {
                    drawerElement.style.display = 'none';
                }, 800);
            }
        }
    }, [drawer])
    return (
        <div className={'nav-menu'}>
            {!props.drawer ? props.links.map(item => {
                if (item.type === 'link') {
                    return (<a href={item.url} key={item.key}>
                        <div className={'menu-item'}>{item.title}</div>
                    </a>);
                } else {
                    return (
                        <Dropdown
                            key={item.key}
                            overlay={menuCreator(item.cascader)}
                            placement={'bottomRight'}
                            arrow={true}
                        >
                            <a href={item.url}><div className={'menu-item'}>{item.title}</div></a>
                        </Dropdown>
                    );
                }
            }) : <div>
                <Burger
                    opened={drawer}
                    onClick={() => handleDrawer(!drawer)}
                />
                <div
                    id={'drawer'}
                    className={'drawer'}
                >
                    <div className={'drawer-inside'}>
                    {props.links.map(item => {
                        if (item.type === 'link') {
                            return (<a className={'drawer-menu-item'} href={item.url} key={item.key}>{item.title}</a>);
                        } else {
                            return (
                                <div className={'cascader'} key={item.key}>
                                    <div className={'drawer-menu-item'}>{item.title}</div>
                                    {item.cascader.map(cascade => {
                                        return(
                                            <a
                                                className={'drawer-cascader-item'}
                                                key={cascade.key}
                                                href={cascade.url}
                                            >
                                                {cascade.title}
                                            </a>
                                        );
                                    })}
                                </div>
                            );
                        }
                    })}
                    </div>
                </div>
            </div>}
        </div>
    );
}

NavMenu.defaultProps = {
    drawer: false,
    links: []
};

export default NavMenu;
