import React from 'react';
import { Breadcrumb } from 'antd';
import PropTypes from 'prop-types';

function BreadCrumb(props) {
    return (
        <div className={'breadcrumb'} style={{ margin: '.5rem' }}>
            <Breadcrumb>
                {props.data.map(item => {
                    return(
                        (typeof item.URL === 'string' && item.URL?.trim() !== '') ?
                            <Breadcrumb.Item key={Math.random()}><a href={item.URL} target={'_blank'} rel={'noreferrer'}>{item.title}</a></Breadcrumb.Item> :
                            <Breadcrumb.Item key={Math.random()}>{item.title}</Breadcrumb.Item>
                    );
                })}
            </Breadcrumb>
        </div>
    );
}

BreadCrumb.propTypes = {
    data: PropTypes.array.isRequired
};

export default BreadCrumb;
