import React, {useCallback, useEffect, useState} from 'react';
import './App.style.scss';
import Header from '../Header/Header';
import Body from '../Body/Body';
import Footer from '../Footer/Footer';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {setConfig} from '../../../features/config/config';
import moment from 'moment';

function App() {
    const dispatch = useDispatch();
    const cdn = useSelector((state) => state['config']['cdn']);
    const loading = useSelector((state) => state['config']['loading']);
    const [event, handleEvent] = useState(false);
    const [config, handleConfig] = useState({
        menu: [],
        socialMedia: {
            twitter: null,
            instagram: null,
            youtube: null,
            telegram: null
        },
        contact: {
            email: null,
            phoneNumber: null,
            address: null,
            about: null
        }
    });
    const headerListener = useCallback(() => {
        if(!event){
            window.localStorage.setItem('lastScrollTop', '0');
            window.localStorage.setItem('headerTurn', 'true');
            document.addEventListener('scroll', () => {
                const label1 = document.getElementById('page-header-label-1');
                const label2 = document.getElementById('page-header-label-2');
                const lastScrollTop = parseInt(window.localStorage.getItem('lastScrollTop'));
                label1.style.top = getComputedStyle(label1).top;
                label2.style.top = getComputedStyle(label2).top;
                if ((document.body.getBoundingClientRect()).top > lastScrollTop) {
                    const label1OldTop = parseInt(label1.style.top.match(/^(^-?\d+(?:\.\d+)?)(.*)$/)[1]);
                    const label2OldTop = parseInt(label2.style.top.match(/^(^-?\d+(?:\.\d+)?)(.*)$/)[1]);
                    if (label1OldTop < 0) {
                        label1.style.top = (label1OldTop + 8).toString() + 'px';
                        label2.style.top = (label2OldTop + 8).toString() + 'px';
                    }
                } else if ((document.body.getBoundingClientRect()).top < lastScrollTop) {
                    const label1OldTop = parseInt(label1.style.top.match(/^(^-?\d+(?:\.\d+)?)(.*)$/)[1]);
                    const label2OldTop = parseInt(label2.style.top.match(/^(^-?\d+(?:\.\d+)?)(.*)$/)[1]);
                    if (label1OldTop > -48) {
                        label1.style.top = (label1OldTop - 8).toString() + 'px';
                        label2.style.top = (label2OldTop - 8).toString() + 'px';
                    }
                }
                const newLastScrollTop = document.body.getBoundingClientRect().top.toString();
                window.localStorage.setItem('lastScrollTop', newLastScrollTop);
            });
            handleEvent(true);
        }
    }, [event]);
    useEffect(() => {
        headerListener();
        axios({
            url: cdn + 'config/getClient',
            method: 'POST'
        })
            .then(res => {
                if(res.data.hasOwnProperty('success')){
                    if(res.data.success){
                        handleConfig(res.data.config);
                        dispatch(setConfig(res.data.config));
                    }
                }
            })
    }, [cdn, dispatch, headerListener]);
    useEffect(() => {
        let time = 0;
        const startTime = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');
        setInterval(() => {
            time += 2;
            if(time >= 10){
                axios({
                    url: cdn + 'statistics/set',
                    method: 'POST',
                    data: {
                        path: window.location.pathname,
                        title: document.title,
                        sessionTime: time,
                        startTime: startTime
                    }
                })
            }
        },2000);
    }, [cdn]);
    return (
        <div className={'app'}>
            <Header config={config}/>
            <Body config={config}/>
            <Footer config={config}/>
            <div className={'page-loading'} style={{ display: !loading && 'none' }}>
                <div className={'gooey'}>
                    <span className={'dot'}/>
                    <div className={'dots'}>
                        <span/>
                        <span/>
                        <span/>
                    </div>
                </div>
            </div>
        </div>
    );
}

App.propTypes = {};
App.defaultProps = {};

export default App;
