import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import NotFound from '../NotFound/NotFound';
import createPage from '../../createPage';
import {stopLoading} from '../../../features/config/config';

function IndexPage() {
    const dispatch = useDispatch();
    const cdn = useSelector((state) => state['config']['cdn']);
    const [notFound, handleNotFound] = useState(false);
    const [pageData, handlePageData] = useState(null);
    useEffect(() => {
        axios({
            url: cdn + 'pages/getClient',
            method: 'POST',
            data: {
                path: '/' + window.location.pathname.split('/')[1] + '/index'
            }
        })
            .then(res => {
                if(res.data.hasOwnProperty('success')) {
                    if(res.data.success) {
                        handlePageData(res.data.page);
                        dispatch(stopLoading());
                    }else {
                        handleNotFound(true);
                        dispatch(stopLoading());
                    }
                }else{
                    handleNotFound(true);
                    dispatch(stopLoading());
                }
            })
            .catch(() => {
                handleNotFound(true);
                dispatch(stopLoading());
            })
    }, [cdn, dispatch]);
    return (notFound ? <NotFound/> :
            <div className={'index-page'}>
                {pageData !== null && createPage(pageData)}
            </div>
    );
}

export default IndexPage;
