import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ContentCorner from '../ContentCorner/ContentCorner';
import axios from 'axios';
import {useSelector} from 'react-redux';

function LastContents(props) {
    const cdn = useSelector((state) => state['config']['cdn']);
    const [content, handleContent] = useState([]);
    useEffect(() => {
        axios({
            url: cdn + 'config/getClient',
            method: 'POST'
        })
            .then(res => {
                if(res.data.hasOwnProperty('success')){
                    if(res.data.success){
                        handleContent(res.data.config['lastContents'].map(item => {
                            return {url: item.path, text: item.title};
                        }));
                    }
                }
            })
    }, [cdn]);
    return (
        <ContentCorner
            className={props.className}
            color={props.color}
            backgroundColor={props.backgroundColor}
            title={'Son Eklenenler'}
            content={content}
        />
    );
}

LastContents.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    backgroundColor: PropTypes.string
};

export default LastContents;
