import CoverImage from './common-use/CoverImage/CoverImage';
import ButtonComponent from './common-use/ButtonComponent/ButtonComponent';
import TitleComponent from './common-use/TitleComponent/TitleComponent';
import TextComponent from './common-use/TextComponent/TextComponent';
import BigCard from './common-use/BigCard/BigCard';
import Slider from './common-use/Slider/Slider';
import BreadCrumb from './common-use/BreadCrumb/BreadCrumb';
import AlertBox from './common-use/AlertBox/AlertBox';
import EmbedYoutube from './common-use/EmbedYoutube/EmbedYoutube';
import Comments from './common-use/Comments/Comments';
import ShareBar from './common-use/ShareBar/ShareBar';
import Gallery from './common-use/Gallery/Gallery';
import Image from './common-use/Image/Image';
import PageInfoBox from './common-use/PageInfoBox/PageInfoBox';
import Calendar from './common-use/Calendar/Calendar';
import Cards from './common-use/Cards/Cards';
import Tags from './common-use/Tags/Tags';
import PersonBox from './common-use/PersonBox/PersonBox';
import FileList from './common-use/FileList/FileList';
import LastContents from './common-use/LastContents/LastContents';
import ContentCorner from './common-use/ContentCorner/ContentCorner';
import LastComments from './common-use/LastComments/LastComments';
import ContentListing from './common-use/ContentListing/ContentListing';

const locator = ({location, align}) => {
    if(location === 'vertical' && align === 'left')
        return { flexFlow: 'column', alignItems: 'inherit' };
    else if(location === 'vertical' && align === 'right')
        return { flexFlow: 'column', alignItems: 'inherit'};
    else if(location === 'vertical' && align === 'center')
        return { flexFlow: 'column', alignItems: 'center' };
    else if(location === 'vertical' && align === 'space-between')
        return { flexFlow: 'column', alignItems: 'space-between' };
    else if(location === 'horizontal' && align === 'left')
        return { flexFlow: 'row', justifyContent: 'flex-start' };
    else if(location === 'horizontal' && align === 'right')
        return { flexFlow: 'row', justifyContent: 'flex-end' };
    else if(location === 'horizontal' && align === 'center')
        return { flexFlow: 'row', justifyContent: 'center' };
    else if(location === 'horizontal' && align === 'space-between')
        return { flexFlow: 'row', justifyContent: 'space-between' };
}

const findChildren = (key, source) => {
    return source.filter(item => key === item.key)[0];
}

const createBox = ({key, location, align, childrenList, childrenSource, pageKey, commentCount, createTime,  maxWidth=null}) => {
    return(<div
        key={'box-' + key}
        className={'build-box'}
        style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: maxWidth !== null ? maxWidth.toString() + 'px' : '100%',
            minWidth: maxWidth !== null && maxWidth.toString() + 'px',
            ...locator({location, align})
        }}
    >
        {childrenList.map(childKey => {
            const child = findChildren(childKey, childrenSource);
            if(child.type === 'component'){
                return createComponent({
                    key: child.key,
                    child: child,
                    pageKey: pageKey,
                    createTime: createTime,
                    commentCount: commentCount
                });
            }else{
                return createBox({
                    key: child.key,
                    maxWidth: child.width,
                    location: child.location,
                    align: child.align,
                    childrenList: child.children,
                    childrenSource, pageKey, commentCount, createTime
                })
            }
        })}
    </div>);
}

const createComponent = ({key, child, pageKey=null, createTime=null, commentCount=null}) => {
    if(child.componentType === 'button'){
        return <ButtonComponent
            key={'component-' + key}
            type={child.content[child.componentType].type}
            text={child.content[child.componentType].text}
            url={child.content[child.componentType].url}
        />
    }
    else if(child.componentType === 'title'){
        return <TitleComponent
            key={'component-' + key}
            color={child.content[child.componentType].color}
            level={child.content[child.componentType].level}
            value={child.content[child.componentType].value}
        />
    }
    else if(child.componentType === 'text'){
        return <TextComponent
            key={'component-' + key}
            data={child.content[child.componentType].data}
        />
    }
    else if(child.componentType === 'bigCard'){
        return <BigCard
            key={'component-' + key}
            backgroundColor1={child.content[child.componentType].backgroundColor1}
            backgroundColor2={child.content[child.componentType].backgroundColor2}
            fontColor={child.content[child.componentType].fontColor}
            value={child.content[child.componentType].value}
            url={child.content[child.componentType].url}
            blank={child.content[child.componentType].blank}
            radius={child.content[child.componentType].radius}
        />
    }
    else if(child.componentType === 'slider'){
        return <Slider
            key={'component-' + key}
            auto={child.content[child.componentType].auto}
            data={child.content[child.componentType].list}
        />
    }
    else if(child.componentType === 'breadcrumb'){
        return <BreadCrumb
            key={'component-' + key}
            data={child.content[child.componentType].list}
        />
    }
    else if(child.componentType === 'alertBox'){
        return <AlertBox
            key={'component-' + key}
            type={child.content[child.componentType].type}
            title={child.content[child.componentType].title}
            content={child.content[child.componentType].content}
            icon={child.content[child.componentType].icon}
        />
    }
    else if(child.componentType === 'embedYoutube'){
        return <EmbedYoutube
            key={'component-' + key}
            url={child.content[child.componentType].url}
        />
    }
    else if(child.componentType === 'comments'){
        return <Comments
            key={'component-' + key}
            pageKey={pageKey}
        />
    }
    else if(child.componentType === 'shareBar'){
        return <ShareBar
            key={'component-' + key}
        />
    }
    else if(child.componentType === 'gallery'){
        return <Gallery
            key={'component-' + key}
            data={child.content[child.componentType].list}
        />
    }
    else if(child.componentType === 'image'){
        return <Image
            key={'component-' + key}
            url={child.content[child.componentType].url}
            caption={child.content[child.componentType].caption}
        />
    }
    else if(child.componentType === 'pageInfoBox'){
        return <PageInfoBox
            key={'component-' + key}
            date={createTime}
            commentCount={parseInt(commentCount)}
            person={child.content[child.componentType].person}
            showCommentCount={child.content[child.componentType].showCommentCount}
            showDate={child.content[child.componentType].showDate}
        />
    }
    else if(child.componentType === 'calendar'){
        return <Calendar
            key={'component-' + key}
            data={child.content[child.componentType].list}
        />
    }
    else if(child.componentType === 'cards'){
        return <Cards
            key={'component-' + key}
            data={child.content[child.componentType].list}
        />
    }
    else if(child.componentType === 'personBox'){
        return <PersonBox
            key={'component-' + key}
            component={child.content[child.componentType].person}
        />
    }
    else if(child.componentType === 'fileList'){
        return <FileList
            key={'component-' + key}
            data={child.content[child.componentType].list}
        />
    }
    else if(child.componentType === 'lastAdded'){
        return <LastContents
            key={'component-' + key}
        />
    }
    else if(child.componentType === 'lastComments'){
        return <LastComments
            key={'component-' + key}
        />
    }
    else if(child.componentType === 'contentCorner'){
        return <ContentCorner
            key={'component-' + key}
            title={child.content[child.componentType].title}
            content={child.content[child.componentType].list}
        />
    }
    else if(child.componentType === 'contentListing'){
        return <ContentListing
            key={'component-' + key}
            titles={child.content[child.componentType].list}
        />
    }
}

export default function createPage(data) {
    const components = [];
    if(data['pageContent'].info.coverImage !== null && data['pageContent'].info.coverImage?.trim() !== ''){
        components.push(<CoverImage
            key={'cover-image'}
            url={data['pageContent'].info.coverImage}
            caption={data['pageContent'].info.coverImageInfo}
        />)
    }
    for (const content of data['pageContent'].content) {
        components.push(createBox({
            key: content.key,
            location: content.location,
            align: content.align,
            childrenList: content.children,
            maxWidth: content.width,
            childrenSource: data['pageContent'].children,
            pageKey: data['pageKey'],
            commentCount: data['commentCount'],
            createTime: data['createTime']
        }));
    }
    if(data.tags.length !== 0){
        components.push(<Tags data={data.tags} key={'page-tags'}/>);
    }
    return components;
}