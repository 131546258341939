import React from 'react';
import './SocialMediaBar.style.scss';

function SocialMediaBar(props) {
    return (
        <div className={'social-media-bar'}>
            {props.links.map((link, index) => {
                return(
                    <a key={index} className={'social-media-link' + (props.white ? ' white' : '')} target={'_blank'} href={link.url} rel={'noreferrer'}>
                        <i className={'fab fa-' + link.logo}/>
                    </a>
                );
            })}
        </div>
    );
}

SocialMediaBar.defaultProps = {
    links: [],
    white: false
};

export default SocialMediaBar;
