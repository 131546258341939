import React from 'react';
import './Footer.style.scss';
import LOGO from '../../../assets/images/logo.png';
import SocialMediaBar from '../SocialMediaBar/SocialMediaBar';
import LastContents from '../../common-use/LastContents/LastContents';

function Footer(props) {
    const socailMediaBarLinks = () => {
        const links = [];
        const logos = Object.keys(props.config.socialMedia);
        for (const link of logos) {
            if(props.config.socialMedia[link] !== null){
                links.push({ url: props.config.socialMedia[link], logo: link});
            }
        }
        return links;
    }
    return (
        <div className={'page-footer inner-space'}>
            <div className={'real-footer'}>
                <div className={'about-us footer-box'}>
                    <img src={LOGO} alt={'Ankara Tıbbiyeliler'}/>
                    <p>{props.config.contact.about}</p>
                    <div className={'contact'}>
                        <a className={'contact-box'} href={'tel:' + props.config.contact.phoneNumber} target={'_blank'} rel={'noreferrer'}>
                            <i className={'fas fa-phone-alt'}/>
                            <div className={'text'}>
                                {props.config.contact.phoneNumber}
                            </div>
                        </a>
                        <a className={'contact-box'} href={'mailto:' + props.config.contact.email} target={'_blank'} rel={'noreferrer'}>
                            <i className={'fas fa-paper-plane'}/>
                            <div className={'text'}>
                                {props.config.contact.email}
                            </div>
                        </a>
                        <a className={'contact-box address'} href={'https://goo.gl/maps/RSg2TRytDZkyeGhx7'} target={'_blank'} rel={'noreferrer'}>
                            <i className={'fas fa-map-marker-alt'}/>
                            <div className={'text'}>
                                {props.config.contact.address}
                            </div>
                        </a>
                    </div>
                    <SocialMediaBar white={true} links={socailMediaBarLinks()}/>
                </div>
                <LastContents
                    className={'footer-box'}
                    color={'#ffffff'}
                    backgroundColor={'#333333'}
                />
            </div>
            <div className={'credit'}>
                <a href={'https://halisyucel.dev'} target={'_blank'} rel={'noreferrer'}>
                    Halis Yücel
                </a>
                <div>
                    tarafından tasarlandı
                </div>
            </div>
        </div>
    );
}

Footer.defaultProps = {
    config: {
        menu: [],
        socialMedia: {
            twitter: null,
            instagram: null,
            youtube: null,
            telegram: null
        },
        contact: {
            email: null,
            phoneNumber: null,
            address: null,
            about: null
        }
    }
};

export default Footer;
