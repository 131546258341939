import React from 'react';
import {Tag} from 'antd';
import PropTypes from 'prop-types';

function Tags(props) {
    return (
        <div className={'tags'} style={{
            display: props.data.length === 0 ? 'none' : 'flex',
            margin: '.5rem',
            flexWrap: 'wrap'
        }}>
            <div style={{ marginRight: '.5rem'}}>Etiketler: </div>
            {props.data.map((tag, index) => <a
                key={index}
                href={'/ara?q=' + tag}
                target={'_blank'}
                rel={'noreferrer'}
            >
                <Tag style={{ marginBottom: '.4rem' }}>{tag}</Tag>
            </a>)}
        </div>
    );
}

Tags.propTypes = {
    data: PropTypes.array.isRequired
};

export default Tags;
