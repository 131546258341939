import React from 'react';
import PropTypes from 'prop-types';
import {Tag} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';

function FileList(props) {
    return (
        <div className={'file-list'} style={{
            margin: '.5rem',
            border: '1px solid #dddddd',
            padding: '.5rem 1.5rem',
        }}>
            <ul style={{
                marginBottom: '0',
                paddingLeft: '.5rem'
            }}>
                {props.data.map(item => {
                    return <li
                        key={item.key}
                        style={{ margin: '.3rem 0' }}
                    >
                        <a href={item.url} target={'_blank'} rel={'noreferrer'} style={{color: '#000000'}}>
                            <span style={{ marginRight: '.6rem' }}>{item.title}</span>
                            <Tag icon={<DownloadOutlined/>}>indir</Tag>
                        </a>
                    </li>;
                })}
            </ul>
        </div>
    );
}

FileList.propTypes = {
    data: PropTypes.array.isRequired
};

export default FileList;
