import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {stopLoading} from '../../../features/config/config';
import axios from 'axios';
import TitleComponent from '../../common-use/TitleComponent/TitleComponent';
import NOT_FOUND from '../../../assets/images/not-found.png';
import './Search.style.scss';

function Search() {
    const dispatch = useDispatch();
    const cdn = useSelector((state) => state['config']['cdn']);
    const [query, handleQuery] = useState(null);
    const [results, handleResults] = useState([]);
    useEffect(() => {
        const _query = new URL(window.location.href).searchParams.get('q');
        handleQuery(_query);
        axios({
            url: cdn + 'pages/search',
            method: 'POST',
            data: { query: _query }
        })
            .then(res => {
                dispatch(stopLoading());
                if(res.data.hasOwnProperty('success'))
                    if(res.data.success){
                        handleResults(res.data.results);
                    }
            })
            .catch(() => {
                dispatch(stopLoading());
            })
    }, [dispatch, cdn]);
    return (
        <div className={'search-page'} style={{ padding: '1rem' }}>
            {results.length === 0 ?
                <div className={'not-found'}>
                    <img src={NOT_FOUND} alt={'sonuç bulunamadı'} />
                    <TitleComponent value={'Aramanız sonuç getirmedi!'} color={'51,51,51'} level={'2'}/>
                </div> :
                <div className={'results'}>
                    <div className={'results-title'}>
                        <b>{query}</b> araması için sonuçlarınız
                    </div>
                    <div className={'list'} style={{ width: '100%' }}>
                        {results.map((item, index) => {
                            console.log(item);
                            return (<a className={'item'} key={index} href={item.path} target={'_blank'} rel={'noreferrer'}>
                                <div className={'item-image'} style={{ backgroundImage: 'url(' + item.image + ')' }}/>
                                <div className={'item-info'}>
                                    <div className={'item-title'}>{item.title}</div>
                                    <div className={'item-description'}>{item.description}</div>
                                </div>
                            </a>);
                        })}
                    </div>
                </div>}
        </div>
    );
}

Search.defaultProps = {};

export default Search;
