import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {useSelector} from 'react-redux';
import './PersonBox.style.scss';

function PersonBox(props) {
    const cdn = useSelector((state) => state['config']['cdn']);
    const [info, handleInfo] = useState({
        title: null
    })
    useEffect(() => {
        axios({
            url: cdn + 'components/get',
            method: 'POST',
            data: {
                title: props.component
            }
        })
            .then(res => {
                if(res.data.hasOwnProperty('component'))
                    handleInfo(res.data.component.component);
            })
    }, [cdn, props.component]);
    return (
        <div className={'person-box'}>
            <a className={'person-box-head'} href={info.url} target={'_blank'} rel={'noreferrer'}>
                {(typeof info.ppUrl === 'string' && info.ppUrl?.trim() !== '') &&
                <div className={'person-photo'} style={{ backgroundImage: 'url(' + info.ppUrl + ')'}}/>}
                <div className={'personal-information'}>
                    <h2 className={'person-name'}>{info.name}</h2>
                    {info.showBio &&
                    <div className={'person-bio'}><i>{info.bio}</i></div>}
                </div>
            </a>
            {info.last3content && <div className={'person-box-body'}>
                <ul>
                    {info['last3contentData'].map((item, index) => {
                        return (<li key={index}><a href={item.url} target={'_blank'} rel={'noreferrer'}>{item.title}</a></li>);
                    })}
                </ul>
            </div>}
        </div>
    );
}

PersonBox.propTypes = {
    component: PropTypes.string.isRequired
};
PersonBox.defaultProps = {
    component: 'bilesen-27686'
};

export default PersonBox;
