import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './ContentListing.style.scss';
import {useSelector} from 'react-redux';
import axios from 'axios';
import {Pagination} from 'antd';

function ContentListing(props) {
    const cdn = useSelector((state) => state['config']['cdn']);
    const id = 'content-listing-' + Math.floor(Math.random() * 10000);
    const [content, handleContent] = useState([]);
    const [page, handlePage] = useState(1);
    const [first, handleFirst] = useState(false);
    useEffect(() => {
        axios({
            url: cdn + 'pages/getContentListingClient',
            method: 'POST',
            data: {
                titles: JSON.stringify(props.titles)
            }
        })
            .then(res => {
                if(res.data.hasOwnProperty('success')){
                    if(res.data.success){
                        handleContent(res.data.result);
                    }
                }
            })
    },[props.titles, cdn]);
    useEffect(() => {
            if(first){
                const elem = document.getElementById(id)
                window.scrollTo(0,elem.offsetTop);
            }else{
                handleFirst(true);
            }
        }, [page, first, id]);
    return (
        <div className={'content-listing'} id={id}>
            {content.slice(((page-1)*10),(page*10)).map((item, index) => {
                return(
                    <a key={index} className={'content'} href={item.path} target={'_blank'} rel={'noreferrer'}>
                        <div className={'content-image'} style={{ backgroundImage: 'url(' + item.image + ')' }}/>
                        <div className={'content-info'}>
                            <div className={'info-title'}>{item.title}</div>
                            <div className={'info-text'}>{item.description}</div>
                        </div>
                    </a>
                );
            })}
            <div className={'pagination'}>
                <Pagination
                    current={page}
                    onChange={page => handlePage(page)}
                    total={content.length === 0 ? 1 : content.length}
                    responsive={true}
                    showSizeChanger={false}
                    defaultPageSize={10}
                />
            </div>
        </div>
    );
}

ContentListing.propTypes = {
    titles: PropTypes.array.isRequired
};
ContentListing.defaultProps = {};

export default ContentListing;
