import React from 'react';
import './Body.style.scss';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from '../../pages/Home/Home';
import Contact from '../../pages/Contact/Contact';
import IndexPage from '../../pages/IndexPage/IndexPage';
import NormalPage from '../../pages/NormalPage/NormalPage';
import Search from '../../pages/Search/Search';
import NotFound from '../../pages/NotFound/NotFound';
import Admin from '../../pages/Admin/Admin';

function Body() {
    return (
        <div className={'page-body inner-space'}>
            <div className={'real-body'}>
                <BrowserRouter>
                    <Switch>
                        <Route path={'/'} exact component={Home}/>
                        <Route path={'/iletisim'} exact component={Contact}/>
                        <Route path={'/ara'} exact component={Search}/>
                        <Route path={'/admin'} exact component={Admin}/>
                        <Route path={'/:scheme'} exact component={IndexPage}/>
                        <Route path={'/:scheme/:page'} exact component={NormalPage}/>
                        <Route component={NotFound}/>
                    </Switch>
                </BrowserRouter>
            </div>
        </div>
    );
}

export default Body;
