import React, {useEffect, useState} from 'react';
import ContentCorner from '../ContentCorner/ContentCorner';
import axios from 'axios';
import {useSelector} from 'react-redux';

function LastComments(props) {
    const cdn = useSelector((state) => state['config']['cdn']);
    const [content, handleContent] = useState([]);
    useEffect(() => {
        axios({
            url: cdn + 'config/getClient',
            method: 'POST'
        })
            .then(res => {
                if(res.data.hasOwnProperty('success')){
                    if(res.data.success){
                        handleContent(res.data.config['lastComments'].map(item => {
                            return {url: item.path, text: <span><i>{item.title}</i> için <i><b>{item.author}</b></i></span>};
                        }));
                    }
                }
            })
    }, [cdn]);
    return (
        <ContentCorner
            title={'Son Yorumlar'}
            content={content}
        />
    );
}

export default LastComments;
