import React from 'react';
import './ShareBar.scss';
import { message } from 'antd';

function ShareBar(props) {
    const url = window.location.href;
    return (
        <div className={'share-bar'}>
            <a className={'icon'} href={'whatsapp://send?text=' + url} rel={'noreferrer'}>
                <i className={'fab fa-whatsapp'}/>
            </a>
            <a className={'icon'} href={'https://twitter.com/share?url=' + url} target={'_blank'} rel={'noreferrer'}>
                <i className={'fab fa-twitter'}/>
            </a>
            <a className={'icon'} href={'https://www.facebook.com/sharer/sharer.php?u=' + url} target={'_blank'} rel={'noreferrer'}>
                <i className={'fab fa-facebook'}/>
            </a>
            <a className={'icon'} href={'https://t.me/share/url?url=' + url} target={'_blank'} rel={'noreferrer'}>
                <i className={'fab fa-telegram'}/>
            </a>
            <div className={'icon copy'} onClick={() => {
                navigator.clipboard.writeText(url).then(() => {
                    message.success('kopyalandı').then(r => r);
                });
            }}>
                <i className={'fas fa-link'}/>
            </div>
        </div>
    );
}

export default ShareBar;
