import React from 'react';
import PropTypes from 'prop-types';
import './BigCard.style.scss';

function BigCard(props) {
    return (
        <div className={'big-card'} style={{
            background: 'linear-gradient(45deg, rgba(' + props.backgroundColor1 + ',1) 0%, rgba(' + props.backgroundColor2 + ',1) 100%)',
            borderRadius: props.radius ? '.5rem' : '0'
        }}>
            {(typeof props.url === 'string' && props.url?.trim() !== '') ?
                <a
                    style={{ color: 'rgb(' + props.fontColor + ')' }}
                    href={props.url}
                    target={props.blank ? '_blank' : '_self'}
                    rel={'noreferrer'}
                >
                    {props.value}
                </a> :
                <div style={{ color: 'rgb(' + props.fontColor + ')' }}> {props.value} </div>
            }
        </div>
    );
}

BigCard.propTypes = {
    backgroundColor1: PropTypes.string.isRequired,
    backgroundColor2: PropTypes.string.isRequired,
    fontColor: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    url: PropTypes.string,
    blank: PropTypes.bool.isRequired,
    radius: PropTypes.bool.isRequired
};

export default BigCard;
