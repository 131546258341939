import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';


function ButtonComponent(props) {
    return (
        <div className={'button-cms'} style={{ margin: '.5rem' }}>
            {(typeof props.url === 'string' && props.url?.trim() !== '') ?
                <a href={props.url}><Button type={props.type}>{props.text}</Button></a> :
                <Button type={props.type}>{props.text}</Button>
            }
        </div>
    );
}

ButtonComponent.propTypes = {
    type: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
}

export default ButtonComponent;
